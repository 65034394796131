import React, { useEffect, useState } from "react";
import axios from "axios";

import Header from "../Stellar/Header";
import Footer from "../Footer/Main";
import PartnershipContent from "./PartnershipContent";
import Aos from "aos";

function Main() {
  const [partnership, setPartnership] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Aos.init();
    fetchData();
    Aos.refresh();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [partnershipResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/stellar/partnership`),
      ]);

      setPartnership(partnershipResponse.data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="index_wraper">
        <Header />
        <PartnershipContent data={partnership} loading={loading} />
        <Footer />
      </div>
    </>
  );
}

export default Main;

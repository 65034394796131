import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";

const Galleryslider = {
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        speed: 1000,
        easing: "linaer",
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
        speed: 1000,
        easing: "linaer",
      },
    },
  ],
};

function Gallery2({ data, sliderRef }) {
  // const [sliderKey, setSliderKey] = useState(0);
  const slider = useRef();

  // Pass the slider reference back to the parent
  useEffect(() => {
    if (sliderRef) {
      console.log("Slider reference:", slider.current);
      sliderRef(slider.current);
    }
  }, [sliderRef]);

  const defaultSettings = {
    slidesToShow: 11.5,
    speed: 5000,
    easing: "linear",
    autoplay: true,
    autoplaySpeed: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3, // Adjust for smaller screens
          speed: 1000,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 5,
          speed: 1000,
        },
      },
    ],
  };

  const settings = {
    ...defaultSettings,
    slidesToShow:
      data.length < defaultSettings.slidesToShow
        ? data.length - 0.5
        : defaultSettings.slidesToShow,
  };

  if (!data || data.length === 0) {
    return <div>No data...</div>; // Render a placeholder or loading spinner
  }

  return (
    <>
      <div className="gallery_direction_right gallery_slider">
        {data && data.length > 0 && (
          <Slider
            ref={slider}
            className="Slider-Who"
            id="gallery_flow_right"
            data-aos="fade-in"
            data-aos-duration={1500}
            {...settings}
          >
            {data.length > 0 &&
              data?.map((item, index) => (
                <div className="item" key={item.id}>
                  <div className="img">
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}${item.bannerImagePath}`}
                      alt="img"
                    />
                  </div>
                </div>
              ))}
          </Slider>
        )}
      </div>
    </>
  );
}

export default Gallery2;

import React from "react";
import { Link } from "react-router-dom";

function BlogDetail({ data }) {
  return (
    <div id="partnership" style={{ marginTop: "300px", marginBottom: "150px" }}>
      <section
        className="blog_detail_section"
        data-aos="fade-up"
        data-aos-duration={2000}
        data-aos-delay={200}
      >
        <div className="container">
          <div className="blog_inner_pannel">
            <div
              className="blog_info"
              data-aos="fade-up"
              data-aos-duration={2000}
            >
              <h1>{data?.title}</h1>
            </div>
            <div
              className="info"
              data-aos="fade-up"
              data-aos-duration={1500}
              dangerouslySetInnerHTML={{ __html: data?.content }}
            ></div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlogDetail;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

import Header from "../Stellar/Header";
// import Banner from '../Menhome/Banner'
import Deliciousmenu from "../Menhome/Deliciousmenu";
// import PrivateSection from '../Menhome/PrivateSection'
// import Review from '../Menhome/Review'
// import Blog from '../Menhome/Blog'
import Footer from "../Footer/Main";
// import WhoWe from '../Menhome/WhoWe'
// import MenuList from '../Menhome/MenuList'
// import PopularDish from "../MenHomeThree/PopularDish";
import HeroSlider from "../HeroSlider/Main";
// import Menulist from "../MenHomeTwo/Menulist";
// import VideoTwo from "../MenHomeTwo/VideoTwo";
import MenuSection from "../MenHomeTwo/MenuSection";
import AbtBanner from "../MenAbout/AbtBanner";
import DiscountSection from "../MenHomeTwo/DiscountSection";
// import WhoWe from "../MenHomeTwo/WhoWe";
// import Testimonial from "../MenHomeTwo/Testimonial";
// import OurTeam from "../MenHomeTwo/OurTeam";
// import Reservation from "../MenHomeTwo/Reservation";
// import Blog from "../MenHomeTwo/Blog";
import Aos from "aos";

function Main() {
  const [logos, setLogos] = useState([]);
  const [about, setAbout] = useState(null);
  const [brands, setBrands] = useState(null);
  const [promotions, setPromotions] = useState(null);
  const [highlights, setHighlights] = useState(null);
  const [events, setEvents] = useState(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.replace("#", ""); // Remove the '#' from the hash
      setTimeout(() => {
        scroller.scrollTo(elementId, {
          smooth: true,
          duration: 500,
          offset: -140,
          spy: true,
          exact: "true",
        });
      }, 500);
    }
  }, [location]);

  useEffect(() => {
    Aos.init();
    fetchData();
    Aos.refresh();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [
        logosResponse,
        aaboutResponse,
        brandsResponse,
        promotionsResponse,
        highlightsResponse,
        eventsResponse,
      ] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/stellar/logos`),
        axios.get(`${process.env.REACT_APP_API_URL}/stellar/about`),
        axios.get(`${process.env.REACT_APP_API_URL}/stellar/brands`),
        axios.get(`${process.env.REACT_APP_API_URL}/stellar/promotions`),
        axios.get(`${process.env.REACT_APP_API_URL}/stellar/highlight`),
        axios.get(`${process.env.REACT_APP_API_URL}/stellar/events`),
      ]);

      setLogos(logosResponse.data);
      setAbout(aaboutResponse.data);
      setBrands(brandsResponse.data);
      setPromotions(promotionsResponse.data);
      setHighlights(highlightsResponse.data);
      setEvents(eventsResponse.data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="index_wraper">
        <Header />
        <HeroSlider data={logos} />
        {/* <Menulist /> */}
        {/* <VideoTwo /> */}
        <AbtBanner data={about} />
        <div id="brands">
          <MenuSection data={brands} labels={brands?.StellarBrandLabels} />
        </div>
        <div id="promotions">
          <MenuSection
            data={promotions}
            labels={promotions?.StellarPromotionLabels}
          />
        </div>
        {/* <DiscountSection data={highlights} /> */}
        {/* <WhoWe /> */}
        {/* <Testimonial /> */}
        {/* <OurTeam /> */}
        {/* <Reservation /> */}
        {/* <Blog /> */}
        <Deliciousmenu data={events} />
        <Footer />
      </div>
    </>
    // <div className='page_wrapper'>
    //   <Header />
    //   <Banner />
    //   <MenuList />
    //   <Deliciousmenu />
    //   <WhoWe />
    //   <Review />
    //   <PrivateSection />
    //   <Blog />
    //   <Footer />
    // </div>
  );
}

export default Main;

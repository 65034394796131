import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Gallery2 from "../MenMenuList/Gallery2";

function MenuSection({ data, labels }) {
  const [tabMenu, setTabActive] = useState({ a: true });
  const sliderRefs = useRef({});

  useEffect(() => {
    if (labels?.length > 0) {
      setTabActive({ [labels[0].id]: true });
    }
  }, [labels]);

  const handleTabClick = (id) => {
    setTabActive({ [id]: true });

    // Trigger slider resize when the tab becomes active
    setTimeout(() => {
      if (sliderRefs.current[id]) {
        sliderRefs.current[id].slickGoTo(0); // Reset to the first slide
        sliderRefs.current[id].innerSlider.onWindowResized(); // Trigger a manual resize
      }
    }, 0);
  };

  return (
    <>
      <section className="our_menu_section row_inner_am light_texchur">
        <div className="container">
          <div className="section_title text-center">
            {data?.subTitle && (
              <span
                className="sub_text mb-1"
                data-aos="fade-down"
                data-aos-duration={1500}
              >
                {data?.subTitle}
              </span>
            )}
            {data?.title && (
              <h2 data-aos="zoom-in" data-aos-duration={1500}>
                {data?.title}
              </h2>
            )}
          </div>
          <ul
            className="nav nav-tabs"
            id="myTab"
            role="tablist"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            {labels?.length > 0 &&
              labels?.map((item, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    className={`nav-link ${tabMenu[item.id] && "active"}`}
                    // onClick={() => tabActive({ [item.id]: true })}
                    onClick={() => handleTabClick(item.id)}
                    id={`${item.id}-tab`}
                    data-toggle="tab"
                    to={`#${item.id}`}
                    role="tab"
                    aria-controls={`item.id`}
                    aria-selected="true"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
          </ul>
          <div
            className="tab-content"
            id="myTabContent"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            {labels?.length > 0 &&
              labels?.map((item, index) => (
                <div
                  className={`tab-pane fade ${
                    tabMenu[item.id] && "show active"
                  }`}
                  id={`${item.id}`}
                  role="tabpanel"
                  aria-labelledby={`${item.id}-tab`}
                  key={item.id}
                >
                  <div className="container">
                    <Gallery2
                      data={
                        item?.StellarBrandLabelImages ||
                        item?.StellarPromotionLabelImages
                      }
                      sliderRef={(ref) => (sliderRefs.current[item.id] = ref)}
                    />
                    <div className="text-center mt-5">
                      <Link to="#" className="btn btn_primary">
                        visit {item?.label}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default MenuSection;
